<template>
  <v-app>
    <div class="row">
      <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Manage finance transaction histories</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\ Finance transaction histories
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <div class="card-toolbar">
                    <v-btn  @click="exportExcel()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-file-export</v-icon>&nbsp;
                     Export
                    </v-btn>
                    <v-btn  @click="addIncome()" class="mt-4 ml-2 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-file-export</v-icon>&nbsp;
                     Add income
                    </v-btn>
                    <v-btn  @click="addExpense()" class="mt-4 ml-2 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-file-export</v-icon>&nbsp;
                     Add expense
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body ">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" md="4">
                    <v-text-field label="Payer name, phone, email, transaction id"
                                  type="text"
                                  dense
                                  outlined v-on:keyup.enter="getAllHistories()"
                                  v-model="search.name"/>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select label="Payment method" outlined dense :items="payment_methods"
                              item-text="title"
                              item-value="value" v-on:keyup.enter="getAllHistories()"
                              v-model="search.payment_method"></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-menu
                        ref="menuDate"
                        v-model="menuStartDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="search.start_date"
                            label="View from date"
                            :error="$v.search.start_date.$error"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.start_date"
                          outlined
                          dense
                          no-title
                          @input="menuStartDate = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-menu
                        ref="menuDate"
                        v-model="menuEndDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="search.end_date"
                            label="View up to date"
                            :error="$v.search.end_date.$error"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.end_date"
                          outlined
                          dense :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"

                          no-title
                          @input="menuEndDate = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-autocomplete  v-model="search.month" outlined label="Months"
                                     item-text="text" :items="months"
                                     item-value="value"   v-on:keyup.enter="getAllHistories()"
                                     dense
                                     clearable>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-select label="Type" outlined dense :items="statuses" item-text="title"
                              item-value="value"   v-on:keyup.enter="getAllHistories()"
                              v-model="search.status"></v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="text-right">
                    <v-btn
                        @click="getAllHistories()"
                        class="btn btn-primary btn-search w-35"

                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div class="">
                <table class="table">
                  <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Type</th>
                    <th class="px-3 wrap-column">Paid for</th>
                    <th class="px-3 wrap-column">Payer name</th>
                    <th class="px-3 wrap-column">Payer phone</th>
                    <th class="px-3 wrap-column">Payer relation</th>
                    <th class="px-3 wrap-column">GST</th>
                    <th class="px-3 wrap-column">Discount</th>
                    <th class="px-3 wrap-column">Total amount</th>
                    <th class="px-3 wrap-column">Paid amount</th>
                    <th class="px-3 wrap-column">Paid date</th>
                    <th class="px-3 wrap-column">Nepali date</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-show="transaction_histories.length > 0" v-for="(history, index) of transaction_histories" :key="index">
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium" :class="history.type == 'expense' ? 'text-danger' : 'text-success'">
                             {{ history.type.toUpperCase() }}
                      </span>


                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">
                        {{ history.paid_for }}
                      </span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">{{ history.payer_name }}</span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">{{ history.payer_mobile }}</span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">{{ history.payer_relation_text }}</span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">Rs.{{ history.gst_amount || '0.00' }}</span>
                    </td>
                    <td class="px-3 wrap-column">
                      <span class="font-weight-medium">Rs.{{ history.discount_amount || '0.00' }}</span>
                    </td>
                    <td class="px-3 wrap-column"><span class="font-weight-medium">Rs.{{ history.total_amount }}</span></td>
                    <td class="px-3 wrap-column"> <span class="font-weight-medium">Rs.{{ history.is_quote ? '0.00' : history.paid_amount }}</span></td>
                    <td class="px-3 wrap-column"> <span class="font-weight-medium">{{ history.payment_date }}</span></td>
                    <td class="px-3 wrap-column"> <span class="font-weight-medium">{{ history.payment_date_bs }}</span></td>
<!--                    <td>
                      <i class="fas" :class="history.is_locked?'fa-lock':'fa-lock-open'"></i>
                      <i class="fas fa-receipt ml-3" v-if="history.invoice_id"
                         @click="openInvoice(history.invoice_id)"></i>
                      <i class="fas fa-edit ml-3" v-if="history.is_quote" @click="editHistory(history)"></i>
                    </td>-->
                    <td class="pr-0 text-center">
<!--                      <i class="fas" :class="history.is_locked?'fa-lock':'fa-lock-open'"></i>-->

                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="invoiceLocked(history)" class="navi-link">
                                <span class="navi-icon">
                                  <i :class="history.is_locked?'fas fa-lock':'fas fa-lock-open'"></i>
                                </span>
                                <span class="navi-text">{{ history.is_locked?'Locked':'Unlocked' }}</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="history.preview_receipt && history.payment_status == 'paid'">
                              <a @click="openInvoice(history.preview_receipt)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-receipt"></i>
                                </span>
                                <span class="navi-text">View receipt</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="history.invoice_url">
                              <a @click="openInvoice(history.invoice_url)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-file-invoice"></i>
                                </span>
                                <span class="navi-text">View invoice</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="history.payment_status == 'pending'" >
                              <a @click="editHistory(history)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                    <!--                  <td>{{history.payer_relation}}</td>-->
                  </tr>
                  <tr v-if="transaction_histories.length == 0">
                    <td colspan="12" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-pagination  v-if="transaction_histories.length > 0"
                               @input="getAllHistories"  class="pull-right mt-7"
                               v-model="page"
                               :total-rows="total"
                               :per-page="perPage"
                               first-number
                               last-number
                ></b-pagination>
              </div>
<!--              <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            &lt;!&ndash;begin: Wizard Form&ndash;&gt;
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>
                  <span>Manage finance transaction histories</span>
                </h4>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                Finance transaction histories

              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 text-right">
                <v-btn class="btn btn-primary text-white ml-2" @click="exportExcel()">Export</v-btn>
              </div>
            </div>


          </div>
        </div>-->
            </div>
          </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import {SiteSettingMixin} from "@/mixins/SiteSettingMixin";
import FinanceMixin from "@/mixins/FinanceMixin";
import FinancialHistoryService from "@/core/services/finance/history/FinancialHistoryService";
import {API_URL} from "@/core/config";
import {required} from "vuelidate/lib/validators";

const historyService = new FinancialHistoryService();

export default {
  name: "financial_history",
  mixins: [SiteSettingMixin, FinanceMixin],
  validations: {
    search: {
      start_date: {required},
      end_date: {required},
    }
  },
  data() {
    return {
      transaction_histories: [],
      search: {
        title: null,
        status: '',
        start_date:'',
        end_date: '',
      },
      payment_methods: [
        {
          title: 'Bank',
          value: 'bank'
        },
        {
          title: 'Cash',
          value: 'cash'
        },
        {
          title: 'Khalti',
          value: 'khalti'
        },
        {
          title: 'Esewa',
          value: 'esewa'
        },
        {
          title: 'Connect Ips',
          value: 'connect_ips'
        },

      ],
      statuses: [
        {title: "Income", value: 'income'},
        {title: "Expense", value: 'expense'},
      ],
      total: null,
      perPage: null,
      page: null,
      menuStartDate: false,
      menuEndDate: false,
      months:[
        {value:'01',text:'January'},
        {value:'02',text:'February'},
        {value:'03',text:'March'},
        {value:'04',text:'April'},
        {value:'05',text:'May'},
        {value:'06',text:'June'},
        {value:'07',text:'July'},
        {value:'08',text:'August'},
        {value:'09',text:'September'},
        {value:'10',text:'October'},
        {value:'11',text:'November'},
        {value:'12',text:'December'},
      ],
    };
  },
  mounted() {
    this.search.status = this.$route.params.status || '';
    this.getAllHistories()
  },
  methods: {
    getAllHistories(){
      this.getHistories(this.search, this.page);
    },
    detail(id) {
      this.$tabs.open({name: "transaction-photo", params: {id: id}});
    },
    reset() {
      this.search = {}
      this.getHistories(this.search, this.page)
    },
    exportExcel() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        let url = API_URL + "user/finance-export"
        if (this.search) {
          let query = new URLSearchParams(this.search).toString();
          if (query)
            url = url + '?' + query;
        }
        window.open(url)
      }
    },
    addIncome(){
      this.$tabs.open({name: 'finance-history-type', params: {type: 'income'}})
    },
    addExpense(){
      this.$tabs.open({name: 'finance-history-type', params: {type: 'expense'}})
    },
    editHistory(history) {
      this.$tabs.close().then(response=>{
        this.$tabs.open({name: "finance-history-type-update", params: {type: history.type, id: history.id}});
      })
    },
    openInvoice(url) {
      window.open(url, '_blank');
    },

    invoiceLocked(history) {
      this.$confirm({
        message: `Are you sure you want to locked this item ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let data = {
              is_locked: !history.is_locked,
            }
            historyService.update(history.id, data).then(response => {
              this.$snotify.success("Update successfully");
              this.getAllHistories()
            }).catch(() => {})
          }
        }
      });
    }
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
